import React from 'react';
import MUIDataTable from 'mui-datatables';

const applicationsTable = ({ title, data, columns, onSearchOpen, options }) => {
  
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );

}


export default applicationsTable;