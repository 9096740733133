import React from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import ApplicationInformationCard from './ApplicationInformationCard';
import ApplicationBodyCard from './ApplicationBodyCard';

const useStyles = makeStyles((theme) => ({
  informationCard: {
    height: '100%'
  }
}));

const ApplicationInformation = ({application}) => {
  const classes = useStyles();

  return (
      <Container >
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
              <Grid
                item
                key={1}
                lg={6}
                md={6}
                xs={12}
              >
                <ApplicationInformationCard
                  className={classes.informationCard}
                  application={application}
                />
              </Grid>

              <Grid
                item
                key={2}
                lg={6}
                md={6}
                xs={12}
              >
                <ApplicationBodyCard
                  className={classes.informationCard}
                  application={application}
                />
              </Grid>
          </Grid>
        </Box>
      </Container>
  );
};

export default ApplicationInformation;