import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './JsonModal.css';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';

const JsonModal = ({ handleModalClose }) => {

  const modalInfo = useSelector(state => state.modal);

  return (
    <Modal
      show={modalInfo.isModalOpen}
      onHide={handleModalClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      animation
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalInfo.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <ReactJson src={modalInfo.json} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="close-btn" onClick={handleModalClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JsonModal;
