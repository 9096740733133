import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import {
    Box,
    Container,
    makeStyles,
} from '@material-ui/core';
import Page from '../../components/Page';
import ApplicationsTable from './applicationsTable';
import RefreshIcon from '@material-ui/icons/Autorenew'
import AddIcon from '@material-ui/icons/Add'
import { IconButton } from '@material-ui/core';
import ToolTip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const InProgressApps = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const applications = useSelector(state => state.application?.inProgress?.items);
    const lastEvaluatedKey = useSelector(state => state.application?.inProgress?.lastEvaluatedKey);
    const user = useSelector(state => state.session.user);

    const reloadData = () => dispatch(actions.applicationActions.getApplicationsAction(0));

    const getApplicationChunk = () => {
        if(lastEvaluatedKey){
            dispatch(actions.applicationActions.getApplicationsPaginationAction(0, lastEvaluatedKey));
        }
    }

    const columns = [
        {
            name: 'applicationId',
            label: 'ApplicationId'
        },
        {
            name: 'applicationStatus',
            label: 'Application Status',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'createdOn',
            label: 'Created On',
        }
    ]

    const options = {
        filter: false,
        selectableRows: 'none',
        responsive: 'vertical',
        fixedHeader: true,
        pagination: false,
        customToolbar: () => {
            return (
                <>
                    <ToolTip title={'Load More Applications'}>
                        <IconButton variant="outlined" onClick={getApplicationChunk}>
                            <AddIcon />
                        </IconButton>
                    </ToolTip>
                    <ToolTip title={'Refresh'}>
                        <IconButton variant="outlined" onClick={reloadData}>
                            <RefreshIcon />
                        </IconButton>
                    </ToolTip>
                </>
            )
        }
    };

    useEffect(() => {
        dispatch(actions.applicationActions.getApplicationsAction(0));
    }, [user, dispatch])


    return (
        <Page
            className={classes.root}
            title="InProgress"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <ApplicationsTable
                        title={"In progress Applications"}
                        columns={columns}
                        data={applications}
                        options={options}
                    />
                </Box>
            </Container>
        </Page>
    )
}

export default InProgressApps
