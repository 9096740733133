import { enqueueSnackBar, removeSnackBar } from '../types'

const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
      type: enqueueSnackBar,
      payload: {
          ...notification,
          key: key || new Date().getTime() + Math.random(),
      },
  };
};

const removeSnackbar = key => ({
  type: removeSnackBar,
  key,
});

const setSuccessMessage = (response) => {
  return (dispatch) => {
    return dispatch(enqueueSnackbar(
      {
        message: response.message || 'task succeed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          persist: false,
        }
      }));
  }
}

const setErrorMessage = (error) => {
  return (dispatch) => {
    return dispatch(enqueueSnackbar(
      {
        message: error.message ?? 'Something went wrong, please try again later',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          persist: false,
        }
      }));
  }
}


const actions = {
  enqueueSnackbar,
  removeSnackbar,
  setSuccessMessage,
  setErrorMessage
}

export default actions;