import { setModalCloseType, setModalOpenType } from "../types";
import notifierAction from "./notifierAction";
import { getApplicationRequest } from "../../services/applicationService";
import { getMessageRequest } from "../../services/messageService";

import { getAuditMessagesDetailRequest } from "../../services/auditMessage";

const setModalOpenAction = (modalData) => {
  return {
    type: setModalOpenType,
    payload: modalData,
  };
};

const setModalCloseAction = () => {
  return {
    type: setModalCloseType,
  };
};

const openModalWithApplication = (applicationId) => {
  return async (dispatch) => {
    return await getApplicationRequest(applicationId)
      .then((response) => {
        const modal = {
          title: `Application Id: ${applicationId}`,
          json: JSON.parse(response.applicationText),
        };

        return dispatch(setModalOpenAction({ ...modal }));
      })
      .catch((error) => dispatch(notifierAction.setErrorMessage(error)));
  };
};

const openModalWithMessage = (applicationId, messageId) => {
  return async (dispatch) => {
    return await getMessageRequest(applicationId, messageId)
      .then((response) => {
        const modal = {
          title: `Application Id: ${applicationId}`,
          json: JSON.parse(response.body),
        };

        return dispatch(setModalOpenAction({ ...modal }));
      })
      .catch((error) => dispatch(notifierAction.setErrorMessage(error)));
  };
};

const openModalAuditMessage = (applicationId, brokerageAccountId, dlqName) => {
  return async (dispatch) => {
    return await getAuditMessagesDetailRequest(
      applicationId,
      brokerageAccountId,
      dlqName
    )
      .then((response) => {
        let message = [];
        if (dlqName === "fallback" || dlqName === "docv") {
          message = response.message[0].message;
        } else {
          message = response.message.applicationText;
        }

        const modal = {
          title: `Application Id: ${applicationId}`,
          json: JSON.parse(message),
        };

        return dispatch(setModalOpenAction({ ...modal }));
      })
      .catch((error) => dispatch(notifierAction.setErrorMessage(error)));
  };
};

const openModalWithDLQMessage = (applicationId, messageFound) => {
  return async (dispatch) => {
    const modal = {
      title: `Application Id: ${applicationId}`,
      json: JSON.parse(messageFound),
    };
    return dispatch(setModalOpenAction({ ...modal }));
  };
};

const actions = {
  setModalCloseAction,
  openModalWithApplication,
  openModalWithMessage,
  openModalWithDLQMessage,
  openModalAuditMessage,
};

export default actions;
