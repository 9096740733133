import {
    getDLQDocvProxySuccessType
} from '../types'
import {
    getDLQDocvMessageProxyByTypeRequest,
    deleteDLQDocvMessagesByIdRequest,
    processDLQDocvMessagesByTypeRequest
} from '../../services/dlqDocvProxyService';

import { addAuditMessagesRequest } from '../../services/auditMessage';
import notifierAction from './notifierAction';


const getDLQDocvProxySuccess = (response) => {
    return {
        type: getDLQDocvProxySuccessType,
        payload: response
    }
}


const getDLQDocvMessagesAction = (type) => {
    return (dispatch) => {
        return getDLQDocvMessageProxyByTypeRequest(type)
            .then(response => dispatch(getDLQDocvProxySuccess(response)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const deleteDLQDocvMessagesAction = (messages, auditMessagesArray, type) => {
    return (dispatch) => {
        return deleteDLQDocvMessagesByIdRequest(messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest( auditMessagesArray))
            .then(() => dispatch(getDLQDocvMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const processDLQDocvMessagesAction = (messages, auditMessagesArray, type) => {
    return (dispatch) => {
        return processDLQDocvMessagesByTypeRequest(messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest(auditMessagesArray))
            .then(() => dispatch(getDLQDocvMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const actions = {
    getDLQDocvMessagesAction,
    deleteDLQDocvMessagesAction,
    processDLQDocvMessagesAction
}

export default actions;