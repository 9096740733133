import {
    getDLQDocvProxySuccessType
} from '../types'

const dlqDovProxy = (state = {}, action) => {
    switch (action.type) {
        case getDLQDocvProxySuccessType:
            return {
                ...state,
                list: {
                    items: action.payload.list
                }
            }
        default:
            return state
    }
}

export default dlqDovProxy;