import React, { useEffect , useState, useCallback} from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useMsal, UnauthenticatedTemplate } from "@azure/msal-react";

import { useNavigate } from "react-router";

// JWT
import jwt_decode from "jwt-decode";
import actions from "../../redux/actions";
import Page from "../../components/Page";
import { getUserRoles } from '../../utils/aws';
import { validateRoles } from '../../utils/helpers'
import { loginRequest  } from "../../authConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [userRoles, setRoles] = useState([]);
  const user = useSelector(state => state.session.user);
  const session = useSelector((state) => state && state.session);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const getRoles = useCallback(async () => {
    if(user){
      const data = await getUserRoles();
      setRoles(data);
    }
  }, [user]);

  useEffect(() => {
    if( user){
      getRoles()
    }
    
  }, [user, getRoles]);

  useEffect(() => {
    let matches;
    if (!isEmpty(userRoles)) {
      const { roles } = jwt_decode(user.idToken);
      matches = validateRoles(userRoles, roles);

      if(matches.length > 0){ 
        navigate("/dashboard");
      }else{
        navigate("/unauthorized")
      };
    }
  }, [navigate, user, session.loggedIn, userRoles]);

  useEffect(() => {
    if (accounts[0]) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        dispatch(actions.sessionActions.createSessionAction(response));
      })
    }
  }, [dispatch, accounts, instance]);

  return (
    <UnauthenticatedTemplate>
      <Page className={classes.root} title="Login">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                Sign in
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                AOP Admin site
              </Typography>
            </Box>
            <Button
              onClick={() => handleLogin()}
              color="primary"
              fullWidth
              size="large"
              variant="contained"
            >
              Sign in now
            </Button>
          </Container>
        </Box>
      </Page>
    </UnauthenticatedTemplate>
  );
};

export default Home;
