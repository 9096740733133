import React,  { useEffect, useCallback, useState }  from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";


import theme from './theme';
import Loading from './components/Loading';
import Routes from './Routes';
import Notifier from './components/Notifier';

import { msalConfig } from "./authConfig";
import { getAzureCredentials } from "./utils/aws";

const App = () => {
  const [azureCredentials, setAzureCredentials] = useState({});
  
  const msalInstance = new PublicClientApplication(
    msalConfig(azureCredentials)
  );

  const fetchData = useCallback(async () => {
    const data = await getAzureCredentials();
    setAzureCredentials(data);
  }, []);

  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);


  return (
   azureCredentials && 

    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <Loading />
        <GlobalStyles />
        <Notifier />
        <Routes />
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;