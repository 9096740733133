import { requestApi } from '../utils';

/**
 * 
 * getMessagesRequest
 */

export const getMessagesRequest = async (applicationId) => {
  return await requestApi(`/applications/${applicationId}/messages`, 'GET', null)
}

/**
  * getMessageRequest
  */
 export const getMessageRequest = async (applicationId, messageId) => {
  return await requestApi(`/applications/${applicationId}/messages/${messageId}`, 'GET', null)
}