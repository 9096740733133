import { requestApi } from '../utils';

/**
 * 
 * Get a specific amount of dlq id verify messages based on the type.
 */

export const getDLQIdVerifyMessageProxyByTypeRequest = async (type) => {
    return await requestApi(
        `/dlq/id-verify-proxy/${type}/`, 'GET',
        null)
}

/**
 * 
 * Delete dlq id verify messages based on they id.
 */

export const deleteDLQMessagesByIdRequest = async (messages) => {
    return await requestApi(
        `/dlq/id-verify-proxy`, 'DELETE',
        { applicationArray: messages } )
}


/**
* 
* Process a bulk of dlq id verify messages.
*/

export const processDLQIdVerifyMessagesByTypeRequest = async (type, messages) => {
    return await requestApi(
        `/dlq/id-verify-proxy/proccess`, 'POST',
        messages )
}
