import { getMessagesSucessType , getApplicationInfoForMessagesSuccessType } from '../types'
import { getMessagesRequest } from '../../services/messageService';
import { getApplicationRequest } from '../../services/applicationService';
import notifierAction from './notifierAction';


const getMessagesSuccess = (list) => {
  return {
    type: getMessagesSucessType,
    payload: list
  }
}

const getApplicationInfoForMessagesSuccess = (application) => {
  return {
    type: getApplicationInfoForMessagesSuccessType,
    payload: application
  }
}

const getMessagesAction = (applicationId) => {
  return (dispatch) => {
    return getMessagesRequest(applicationId)
    .then(response => dispatch(getMessagesSuccess(response)))
    .catch(error => dispatch(notifierAction.setErrorMessage(error)));
  }
}

const getApplicationInfoForMessagesAction = (applicationId) => {
  return (dispatch) => {
    return getApplicationRequest(applicationId)
    .then(response => dispatch(getApplicationInfoForMessagesSuccess(response)))
    .catch(error => dispatch(notifierAction.setErrorMessage(error)));
  }
}


const actions = {
  getMessagesAction,
  getApplicationInfoForMessagesAction
}

export default actions;