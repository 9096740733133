const getCompletedAppsSuccessType = 'GET_COMPLETED_APPS_SUCCESS_TYPE';
const getIncompleteAppsSuccessType = 'GET_INCOMPLETE_APPS_SUCCESS_TYPE';
const getMissingSnapshotsAppsSuccessType = 'GET_MISSING_SNAPSHOTS_APPS_SUCCESS_TYPE';
const getInProgressAppsSuccessType = 'GET_IN_PROGRESS_APPS_SUCCESS_TYPE';

const getCompletedAppsPaginationSuccessType = 'GET_COMPLETED_APPS_PAGINATION_SUCCESS_TYPE';
const getIncompleteAppsPaginationSuccessType = 'GET_INCOMPLETE_APPS_PAGINATION_SUCCESS_TYPE';
const getMissingSnapshotsAppsPaginationSuccessType = 'GET_MISSING_SNAPSHOTS_APPS_PAGINATION_SUCCESS_TYPE';
const getInProgressAppsPaginationSuccessType = 'GET_IN_PROGRESS_APPS_PAGINATION_SUCCESS_TYPE';

const getApplicationsCountSuccessType = 'GET_APPLICATIONS_COUNT_SUCCESS_TYPE';

export {
  getCompletedAppsSuccessType,
  getIncompleteAppsSuccessType,
  getMissingSnapshotsAppsSuccessType,
  getInProgressAppsSuccessType,
  getCompletedAppsPaginationSuccessType,
  getIncompleteAppsPaginationSuccessType,
  getMissingSnapshotsAppsPaginationSuccessType,
  getInProgressAppsPaginationSuccessType,
  getApplicationsCountSuccessType,
}