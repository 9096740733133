import {
    getMessagesSucessType,
    getApplicationInfoForMessagesSuccessType
} from '../types'

const message = (state = {}, action) => {
    switch (action.type) {
        case getMessagesSucessType:
            return {
                ...state,
                list: action.payload,
            }
        case getApplicationInfoForMessagesSuccessType:
            return {
                ...state,
                application: action.payload,
            }
        default:
            return state
    }
}

export default message;