import {
    getDLQIdVerifyProxySuccessType
} from '../types'
import {
    getDLQIdVerifyMessageProxyByTypeRequest,
    deleteDLQMessagesByIdRequest,
    processDLQIdVerifyMessagesByTypeRequest
} from '../../services/dlqIdVerifyProxyService';

import { addAuditMessagesRequest } from '../../services/auditMessage';
import notifierAction from './notifierAction';


const getDLQIdVerifyProxySuccess = (response) => {
    return {
        type: getDLQIdVerifyProxySuccessType,
        payload: response
    }
}


const getDLQIdVerifyMessagesAction = (type) => {
    return (dispatch) => {
        return getDLQIdVerifyMessageProxyByTypeRequest(type)
            .then(response => dispatch(getDLQIdVerifyProxySuccess(response)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const deleteDLQIdVerifyMessagesAction = (messages, auditMessagesArray, type) => {
    return (dispatch) => {
        return deleteDLQMessagesByIdRequest(messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest(auditMessagesArray))
            .then(() => dispatch(getDLQIdVerifyMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const processDLQIdVerifyMessagesAction = (messages, auditMessagesArray, type) => {
    return (dispatch) => {
        return processDLQIdVerifyMessagesByTypeRequest(type, messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest(auditMessagesArray))
            .then(() => dispatch(getDLQIdVerifyMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const actions = {
    getDLQIdVerifyMessagesAction,
    deleteDLQIdVerifyMessagesAction,
    processDLQIdVerifyMessagesAction
}

export default actions;