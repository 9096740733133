import { requestApi } from '../utils';


/**
 * 
 * Get a specific amount of dlq messages based on the type.
 */

export const getDLQMessageProxyByTypeRequest = async (type) => {
    return await requestApi(
        `/dlq/message-proxy/${type}/`, 'GET',
        null)
}

/**
 * 
 * Delete dlq messages based on they id.
 */

export const deleteDLQMessagesByIdRequest = async (messages) => {
    return await requestApi(
        `/dlq/message-proxy`, 'DELETE',
        { applicationArray: messages })
}


/**
* 
* Process a bulk of dlq messages.
*/

export const processDLQMessagesByTypeRequest = async (type, messages) => {
    return await requestApi(
        `/dlq/message-proxy/proccess`, 'POST',
        messages)
}
