import {
  getCompletedAppsSuccessType,
  getIncompleteAppsSuccessType,
  getMissingSnapshotsAppsSuccessType,
  getInProgressAppsSuccessType,
  getCompletedAppsPaginationSuccessType,
  getIncompleteAppsPaginationSuccessType,
  getMissingSnapshotsAppsPaginationSuccessType,
  getInProgressAppsPaginationSuccessType,
  getApplicationsCountSuccessType,
} from '../types'
import {
  getApplicationsRequest,
  publishApplicationRequest,
  getApplicationRequest,
  getApplicationsCountRequest,
} from '../../services/applicationService';
import notifierAction from './notifierAction';


const getCompletedAppsSuccess = (response) => {
  return {
    type: getCompletedAppsSuccessType,
    payload: response
  }
}

const getIncompleteAppsSuccess = (response) => {
  return {
    type: getIncompleteAppsSuccessType,
    payload: response
  }
}

const getMissingSnapshotsAppsSuccess = (response) => {
  return {
    type: getMissingSnapshotsAppsSuccessType,
    payload: response
  }
}

const getInProgressAppsSuccess = (response) => {
  return {
    type: getInProgressAppsSuccessType,
    payload: response
  }
}

const getCompletedAppsPaginationSuccess = (response) => {
  return {
    type: getCompletedAppsPaginationSuccessType,
    payload: response
  }
}

const getIncompleteAppsPaginationSuccess = (response) => {
  return {
    type: getIncompleteAppsPaginationSuccessType,
    payload: response
  }
}

const getMissingSnapshotsAppsPaginationSuccess = (response) => {
  return {
    type: getMissingSnapshotsAppsPaginationSuccessType,
    payload: response
  }
}

const getInProgressAppsPaginationSuccess = (response) => {
  return {
    type: getInProgressAppsPaginationSuccessType,
    payload: response
  }
}

const getApplicationsCountSuccess = response => {
  return{
    type: getApplicationsCountSuccessType,
    payload: response
  }
}

const getApplicationsAction = (type, lastEvaluatedKey = null) => {
  return (dispatch) => {
    return getApplicationsRequest(type,lastEvaluatedKey)
      .then(response => {
        let functionResult;

        switch (type) {
          case 0: // In-progress
            functionResult = getInProgressAppsSuccess;
            break;
          case 1: // Completed
            functionResult = getCompletedAppsSuccess;
            break;
          case 2: // Incompleted
            functionResult = getIncompleteAppsSuccess;
            break;
          case 3: // Missing Snapshots
            functionResult = getMissingSnapshotsAppsSuccess;
            break;
          default:
            break;
        }

        return dispatch(functionResult(response));
      })
      .catch(error => dispatch(notifierAction.setErrorMessage(error)));
  }
}


const getApplicationsPaginationAction = (type, lastEvaluatedKey) => {
  return (dispatch) => {
    return getApplicationsRequest(type,lastEvaluatedKey)
      .then(response => {
        let functionResult;
        
        switch (type) {
          case 0: //In progress
            functionResult = getInProgressAppsPaginationSuccess;
            break;
          case 1: //Completed
            functionResult = getCompletedAppsPaginationSuccess;
            break;
          case 2: //Incompleted
            functionResult = getIncompleteAppsPaginationSuccess;
            break;
          case 3: //Missing Snapshots
            functionResult = getMissingSnapshotsAppsPaginationSuccess;
            break;
          default:
            break;
        }

        return dispatch(functionResult(response));
      })
      .catch(error => dispatch(notifierAction.setErrorMessage(error)));
  }
}

const publishApplicationAction = (type, applicationId) => {
  return (dispatch) => {
    return getApplicationRequest(applicationId)
      .then(application => publishApplicationRequest(type, JSON.parse(application.applicationText), applicationId))
      .then(response => dispatch(notifierAction.setSuccessMessage(response)))
      .catch(error => dispatch(notifierAction.setErrorMessage(error)));
  }
}

const getApplicationsCountAction = () => {
  return (dispatch) => {
    return getApplicationsCountRequest()
      .then(response => dispatch(getApplicationsCountSuccess(response)))
      .catch(error => dispatch(notifierAction.setErrorMessage(error)));
  }
}

const actions = {
  getApplicationsAction,
  publishApplicationAction,
  getApplicationsPaginationAction,
  getApplicationsCountAction,
} 

export default actions;