import React from 'react';
import {useSelector} from 'react-redux';
import {
  Route,
  Navigate
} from 'react-router-dom'

const PrivateRoute = ({ element, path, children }) => {
  const session = useSelector(state => state.session);
  const loggedIn = session ? session.loggedIn : false;
  return loggedIn ? (<Route path={path} element={element}>{children}</Route>) : (<Navigate to={"/"} replace={true} />)
}

export default PrivateRoute;