/**
 * Format Org and Username correctly for the Serverless Platform backend
 */
export const formatOrgAndUsername = (name = '') => {
   name = name.toString().toLowerCase().replace(/[^a-z\d-]+/gi, '-')
   // Remove multiple instances of hyphens
   name = name.replace(/-{2,}/g, '-')
   if (name.length > 40) {
     name = name.substring(0, 40)
   }
   return name
}

/**
 * Parse query parameters in a URL
 * @param {*} searchString 
 */
export const parseQueryParams = (searchString = null) => {
  if (!searchString) {
    return null
  }

  // Clone string
  let clonedParams = (' ' + searchString).slice(1)

  return clonedParams
    .substr(1)
    .split('&')
    .filter((el) => el.length)
    .map((el) => el.split('='))
    .reduce(
      (accumulator, currentValue) =>
        Object.assign(accumulator, {
          [decodeURIComponent(currentValue.shift())]: decodeURIComponent(currentValue.pop())
        }),
      {}
    )
}

/**
 * Parse hash fragment parameters in a URL
 */
export const parseHashFragment = (hashString) => {
  const hashData = {}
  let hash = decodeURI(hashString)
  hash = hash.split('&')
  hash.forEach((val) => {
    val = val.replace('#', '')
    hashData[val.split('=')[0]] = val.split('=')[1]
  })
  return hashData
}

export const validateRoles = (roles = [], incomingRoles = []) => {

  return incomingRoles.filter(incomingRole => {
    const parsedRole = incomingRole.replace(/trad.tradestation.com\\/g, '');
    return roles.includes(parsedRole)
  });
};