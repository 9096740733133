// react
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { IconButton, Box, Container, makeStyles } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import RefreshIcon from "@material-ui/icons/Autorenew";
import ToolTip from "@material-ui/core/Tooltip";

// utils
import { startCase } from "lodash";

// components
import Page from "../../components/Page";
import JsonModal from "../../components/JsonModal/JsonModal";
import AuditMessagesTable from "./auditMessagesTable";

// redux
import actions from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const AuditMessagePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.auditMessages?.list?.items);

  const user = useSelector((state) => state.session.user);

  const reloadData = () =>
    dispatch(actions.auditMessageActions.getAuditMessagesAction());

  const handleModalClose = () =>
    dispatch(actions.modalActions.setModalCloseAction());

  const handleModalOpen = (meta) => {
    if (meta.rowData && meta.rowData[0] && meta.rowData[1]) {
      const auditMessage = messages.find(data => data.applicationId === meta.rowData[0]);
      const applicationId = meta.rowData[0];
      let brokerageAccountId = '';
      if(auditMessage.dlq === 'fallback'){
        brokerageAccountId = meta.rowData[1];
      }
      dispatch(
        actions.modalActions.openModalAuditMessage(applicationId, brokerageAccountId, auditMessage.dlq)
      );
    }
  };

  const openAction = (value, meta, update) => {
    return (
      <IconButton
        variant="outlined"
        color="secondary"
        onClick={() => handleModalOpen(meta)}
      >
        <OpenInNewIcon />
      </IconButton>
    );
  };

  const formatDate = value => {
    return new Date(value).toISOString();
  };

  const columns = [
    {
      name: "applicationId",
      label: "ApplicationId",
    },
    {
      name: "brokerageAccountId",
      label: "Brokerage Account Id",
    },
    {
      name: "updatedAt",
      label: "Action On",
      options: {
        customBodyRender: formatDate,
      },
    },
    {
      name: "actionLog",
      label: "Action",
      options: {
        customBodyRender: (value) => startCase(value),
      },
    },
    {
      name: "dlq",
      label: "DLQ Name",
      options: {
        customBodyRender: (value) => value ? startCase(value) : '',
      },
    },
    {
      name: "userEmail",
      label: "User",
    },
    {
      name: "Open",
      options: {
        customBodyRender: openAction,
      },
    },
  ];

  const options = {
    filter: false,
    selectableRowsHideCheckboxes: true,
    responsive: "vertical",
    fixedHeader: true,
    pagination: true,
    rowsPerPage: 100,
    customToolbar: () => {
      return (
        <>
          <ToolTip title={"Refresh"}>
            <IconButton variant="outlined" onClick={reloadData}>
              <RefreshIcon />
            </IconButton>
          </ToolTip>
        </>
      );
    },
  };

  useEffect(() => {
    dispatch(actions.auditMessageActions.getAuditMessagesAction());
  }, [user, dispatch]);

  return (
    <Page className={classes.root} title="Completed">
      <Container maxWidth={false}>
        <Box mt={3}>
          <AuditMessagesTable
            title={"Audit Messages"}
            columns={columns}
            data={messages}
            options={options}
          />
        </Box>
        <JsonModal handleModalClose={handleModalClose} />
      </Container>
    </Page>
  );
};

export default AuditMessagePage;
