import { requestApi } from '../utils';

/**
 * 
 * Get a specific amount of dlq fallback messages based on the type.
 */

export const getDLQFallbackProcessMessageByTypeRequest = async (type) => {
    return await requestApi(
        `/dlq/aml-process/${type}/`, 'GET',
        null )
}

/**
 * 
 * Delete dlq fallback messages based on they id.
 */

export const deleteDLQFallbackProcessMessagesByIdRequest = async (messages) => {
    return await requestApi(
        `/dlq/aml-process`, 'DELETE',
        { applicationArray: messages })
}


/**
* 
* Process a bulk of dlq fallback messages.
*/

export const processDLQFallbackProcessMessagesByTypeRequest = async (type, messages) => {
    return await requestApi(
        `/dlq/aml-process/proccess`, 'POST',
        messages )
}
