import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";

//import { useNavigate } from "react-router";

// JWT
// import jwt_decode from "jwt-decode";
import actions from "../../redux/actions";
import Page from "../../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    paddingTop: theme.spacing(3),
  },
  title: {
    paddingTop: theme.spacing(5),
  },
}));

const Unauthorized = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
    dispatch(actions.sessionActions.deleteSessionAction());
  };

  return (
      <Page className={classes.root} title="Login">
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} className={classes.title}>
            <Typography color="textPrimary" variant="h2">
              Sorry, you don't have permission to access this site.
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.button}>
          <Button
            onClick={() => handleLogout()}
            color="primary"
            fullWidth
            size="large"
            variant="contained"
            
          >
            OK
          </Button>
          </Grid>
        </Grid>
      </Page>
  );
};

export default Unauthorized;
