import { requestApi } from "../utils";

/**
 *
 * Get all audit messages.
 */
export const getAuditMessagesRequest = async () => {
  return await requestApi(`/dlq/audit-messages`, "GET", null);
};

/**
 *
 * Get audit message details.
 */
 export const getAuditMessagesDetailRequest = async (applicationId, brokerageAccountId = "N/A", dlqName) => {
  return await requestApi(`/dlq/audit-messages/${applicationId}/${dlqName}`, "POST", { brokerageAccountId: brokerageAccountId });
};

/**
 *
 * Add audit messages.
 */
export const addAuditMessagesRequest = async (messages) => {
  await requestApi(`/dlq/audit-messages/`, "POST", { auditMessagesArray: messages });
};
