import { requestApi } from '../utils';

/**
 * 
 * Get All the applications related to a type and based on the lastEvaluatedKey
 */

export const getApplicationsRequest = async (type, lastEvaluatedKey) => {
  return await requestApi(
    `/applications/${type}/applicationStatus/${50}`,
    'POST',
    { LastEvaluatedKey: lastEvaluatedKey }
  )
}

/**
 * 
 * Publish an application into the SNS Topic based on its type (complete, incomplete, missing snapshots)
 */

export const publishApplicationRequest = async (type, application, applicationId) => {
  return await requestApi(
    `/applications/${applicationId}/publish/${type}`, 'POST',
    { application }
  )
}

/**
 * Get one application based on its applicationId
 */
export const getApplicationRequest = async (applicationId) => {
  return await requestApi(`/applications/${applicationId}`, 'GET', null)
}


/**
 * Count all the applications in the dynamoDbTable
 */
export const getApplicationsCountRequest = async () => {
  return await requestApi(`/applications/count`, 'GET', null, )
}