import { combineReducers } from 'redux';
import session from './sessionReducer';
import message from './messageReducer';
import application from './applicationReducer';
import notifications from './notifierReducer';
import modal from './modalReducer';
import { deleteSessionType } from '../types';
import dlqMessageProxy from './dlqMessageProxyReducer';
import auditMessages from './auditMessages';
import dlqIdVerifyMessageProxy from './dlqIdVerifyProxyReducer';
import dlqDovProxy from './dlqDocvProxyReducer';
import dlqFallbackProcess from './dlqFallbackProcessReducer';

const appReducer = combineReducers({
  session,
  message,
  application,
  notifications,
  modal,
  dlqMessageProxy,
  auditMessages,
  dlqIdVerifyMessageProxy,
  dlqDovProxy,
  dlqFallbackProcess
})

// Reset state when doing log-out
const rootReducer = (state, action) => {
  if (action.type === deleteSessionType) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;