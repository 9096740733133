import sessionActions from './sessionAction';
import messageActions from './messageAction';
import applicationActions from './applicationAction';
import notifierActions from './notifierAction';
import modalActions from './modalAction';
import dqlMessageProxyActions from './dlqMessageProxyAction';
import dlqIdVerifyProxyActions from './dlqIdVerifyProxyAction';
import auditMessageActions from './auditMessages';
import dlqDocvProxyActions from './dlqDocvProxyAction';
import dlqFallbackActions from './dlqFallbackProcessAction';

const allActions = {
    sessionActions,
    messageActions,
    applicationActions,
    notifierActions,
    modalActions,
    dqlMessageProxyActions,
    auditMessageActions,
    dlqIdVerifyProxyActions,
    dlqDocvProxyActions,
    dlqFallbackActions
}

export default allActions