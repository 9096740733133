import {
    getDLQMessageProxySuccessType
} from '../types'
import {
    getDLQMessageProxyByTypeRequest,
    deleteDLQMessagesByIdRequest,
    processDLQMessagesByTypeRequest
} from '../../services/dlqMessageProxyService';

import { addAuditMessagesRequest } from '../../services/auditMessage';
import notifierAction from './notifierAction';


const getDLQMessageProxySuccess = (response) => {
    return {
        type: getDLQMessageProxySuccessType,
        payload: response
    }
}


const getDLQMessagesAction = (type) => {
    return (dispatch) => {
        return getDLQMessageProxyByTypeRequest(type)
            .then(response => dispatch(getDLQMessageProxySuccess(response)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const deleteDLQMessagesAction = (messages, auditMessagesArray,type) => {
    return (dispatch) => {
        return deleteDLQMessagesByIdRequest(messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest(auditMessagesArray))
            .then(() => dispatch(getDLQMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const processDLQMessagesAction = (messages, auditMessagesArray, type) => {
    return (dispatch) => {
        return processDLQMessagesByTypeRequest(type, messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest(auditMessagesArray))
            .then(() => dispatch(getDLQMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const actions = {
    getDLQMessagesAction,
    deleteDLQMessagesAction,
    processDLQMessagesAction
}

export default actions;