import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    makeStyles,
    colors,
    Button
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssigmentIcon from '@material-ui/icons/Assignment';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex'
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    },
    avatar: {
        backgroundColor: colors.red[600],
        height: 56,
        width: 56
    },
}));

const ApplicationInformationCard = ({ className, application, ...rest }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onPublishHandler = () => {
        dispatch(actions.applicationActions.publishApplicationAction(application?.applicationStatus, application?.applicationId));
    }

    const getStatus = (appStatus) => {
        let result;

        switch (appStatus) {
            case 0:
                result = 'In Progress'
                break;
            case 1:
                result = 'Completed'
                break;
            case 2:
                result = 'Incomplete Processed'
                break;
            case 3:
                result = 'Missing Snapshots'
                break;
            default:
                break;
        }

        return result;
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="center"
                    mb={3}
                >
                    <Avatar className={classes.avatar}>
                        <AssigmentIcon />
                    </Avatar>
                </Box>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                >
                    Information
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body2"
                >
                    <b>ApplicationId:</b> {application?.applicationId}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body2"
                >
                    <b>Status:</b> {getStatus(application?.applicationStatus)}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body2"
                >
                    <b>Last Updated:</b> {application?.updatedOn}
                </Typography>
            </CardContent>
            <Box flexGrow={1} />
            <Divider />
            <Box p={2}>
                <Grid
                    container
                    justify="space-between"
                    spacing={2}
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <AccessTimeIcon
                            className={classes.statsIcon}
                            color="action"
                        />
                        <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                        >
                            <b>CreatedOn</b> {application?.createdOn}
                        </Typography>
                    </Grid>
                    {application.applicationStatus !== 0 && 
                        <Grid
                        className={classes.statsItem}
                        item
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onPublishHandler}
                        >
                            Publish
                        </Button>
                    </Grid>
                    }
                </Grid>
            </Box>
        </Card>
    );
};

ApplicationInformationCard.propTypes = {
    className: PropTypes.string
};

export default ApplicationInformationCard;