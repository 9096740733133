import {
    setModalOpenType,
    setModalCloseType,
} from '../types'

const application = (state = { isModalOpen: false, title: null, json: null }, action) => {
    switch (action.type) {
        case setModalOpenType:
            return {
                ...state,
                isModalOpen: true,
                title: action.payload.title,
                json: action.payload.json
            }
        case setModalCloseType:
            return {
                ...state,
                isModalOpen: false,
                title: null,
                json: null
            }
        default:
            return state
    }
}

export default application;