import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Button,
    ListItem,
    makeStyles
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import NavItemNested from './NavItemNested';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        border: 0
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto'
    }
}));

const NavNestedList = ({
    className,
    icon: Icon,
    title,
    nestedItems,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                {...rest}
            >
                <Button
                    
                    className={classes.button}
                    onClick={handleClick}
                >
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    <span className={classes.title}>
                        {title}
                    </span>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </Button>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {nestedItems.map((item) => (
                        <NavItemNested
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
};

NavNestedList.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
    nestedItems: PropTypes.array,
};

export default NavNestedList;
