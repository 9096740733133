import {
    getDLQIdVerifyProxySuccessType
} from '../types'

const dlqIdVerifyMessageProxy = (state = {}, action) => {
    switch (action.type) {
        case getDLQIdVerifyProxySuccessType:
            return {
                ...state,
                list: {
                    items: action.payload.list
                }
            }
        default:
            return state
    }
}

export default dlqIdVerifyMessageProxy;