import {
    getDLQMessageProxySuccessType
} from '../types'

const dlqMessageProxy = (state = {}, action) => {
    switch (action.type) {
        case getDLQMessageProxySuccessType:
            return {
                ...state,
                list: {
                    items: action.payload.list
                }
            }
        default:
            return state
    }
}

export default dlqMessageProxy;