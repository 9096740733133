import { requestApi } from '../utils';

/**
 * 
 * Get a specific amount of dlq docv messages based on the type.
 */

export const getDLQDocvMessageProxyByTypeRequest = async (type) => {
    return await requestApi(
        `/dlq/docv-proxy/${type}/`, 'GET',
        null)
}

/**
 * 
 * Delete dlq docv messages based on they id.
 */

export const deleteDLQDocvMessagesByIdRequest = async (messages) => {
    return await requestApi(
        `/dlq/docv-proxy`, 'DELETE',
        { applicationArray: messages } )
}


/**
* 
* Process a bulk of dlq docv messages.
*/

export const processDLQDocvMessagesByTypeRequest = async (messages) => {
    return await requestApi(
        `/dlq/docv-proxy/proccess`, 'POST',
        messages )
}
