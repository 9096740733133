import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { IconButton } from '@material-ui/core';
import Page from '../../components/Page';
import MessagesTable from './messagesTable';
import JsonModal from '../../components/JsonModal/JsonModal';
import SearchToolBar from '../../components/SearchToolBar';
import ApplicationInformation from './ApplicationInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Messages = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messages = useSelector(state => state.message.list);
  const application = useSelector(state => state.message?.application);

  const handleModalClose = () => dispatch(actions.modalActions.setModalCloseAction());
  const handleModalOpen = meta => {
    if (meta.rowData && meta.rowData[0] && meta.rowData[1]) {
      const applicationId = meta.rowData[0];
      const messageId = meta.rowData[1];
      dispatch(actions.modalActions.openModalWithMessage(applicationId, messageId));
    }
  };


  const openAction = (value, meta, update) => {
    return (
      <IconButton variant="outlined" color="secondary" onClick={() => handleModalOpen(meta)}>
        <OpenInNewIcon />
      </IconButton>
    );
  }

  const onChangeHandler = (e) => {

    let input = e.target.value;
    input = input.trim();

    if (input.length >= 36) {
      dispatch(actions.messageActions.getMessagesAction(input));
      dispatch(actions.messageActions.getApplicationInfoForMessagesAction(input));
    }
  }

  const columns = [
    {
      name: 'applicationId',
      label: 'ApplicationId'
    },
    {
      name: 'messageId',
      label: 'Message Id'
    },
    {
      name: 'messageType',
      label: 'Message Type'
    },
    {
      name: 'createdOn',
      label: 'Created On'
    },
    {
      name: "Open",
      options: {
        customBodyRender: openAction,
      }
    },
  ]

  return (
    <Page
      className={classes.root}
      title="Messages"
    >
      <Container maxWidth={false}>
        <SearchToolBar onChangeHandler={onChangeHandler} />
        {application && <ApplicationInformation application={application}/>}
        <Box mt={3}>
          {
            messages && <MessagesTable
              columns={columns}
              data={messages}
            />
          }
        </Box>
        <JsonModal
          handleModalClose={handleModalClose}
        />
      </Container>
    </Page>
  )
}

export default Messages
