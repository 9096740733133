import { requestApi } from './index'


export const getAzureCredentials = async () => {
  return await requestApi("/aws/azure-credentials", "GET", null);
};

export const getUserRoles = async () => {
  const data = await requestApi("/aws/roles", "GET", null);
  
  return data;
};

