import { createSessionType, deleteSessionType } from '../types';

const createSessionAction = (session) => {
  return {
    type: createSessionType,
    payload: session
  }
}

const deleteSessionAction = () => {
  return {
    type: deleteSessionType
  }
}

const actions = {
  createSessionAction,
  deleteSessionAction
}

export default actions;