import { enqueueSnackBar, removeSnackBar } from '../types'

 const notifier = (state = { list: [] }, action) => {
  switch (action.type) {
    case enqueueSnackBar:
      return {
        ...state,
        list: [
          ...state.list,
          {
            key: action.payload.key,
            ...action.payload,
          }
        ]
      };
    case removeSnackBar:
      return {
        ...state,
        list: state.list.filter(
          notification => notification.key !== action.key,
        ),
      };
    default:
      return state;
  }
};

export default notifier;
