import { getAuditMessagesSucessType } from "../types";

const dlqMessageProxy = (state = {}, action) => {
  switch (action.type) {
    case getAuditMessagesSucessType:
      return {
        ...state,
        list: {
          items: action.payload,
        },
      };
    default:
      return state;
  }
};

export default dlqMessageProxy;
