import React from 'react';
import NotFoundPage from './pages/Error/NotFoundPage';
import PrivateRoute from './components/PrivateRoute';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import {
  Route,
  Routes
} from 'react-router-dom'
import Login from './pages/Login/Login'
import Dashboard from './pages/Dashboard';
import Messages from './pages/Messages';
import CompletedApps from './pages/Applications/CompletedApps';
import IncompletedApps from './pages/Applications/IncompletedApps';
import MissingSnapshotsApps from './pages/Applications/MissingSnapshotsApps';
import InProgressApps from './pages/Applications/InProgressApps';
import DlqMessageProxy from './pages/DLQMessageProxy';
import DlqIdVerifyProxy from './pages/DLQIDVerifyProxy';
import DlqDocvProxy from './pages/DLQDocvProxy';
import DlqFallbackService from './pages/DLQFallbackService';
import AuditMessages from './pages/AuditMessages';
import Unauthorized from './pages/Login/Unauthorized'

const AppRoutes = () => {

  return (
    <Routes>
        {/* Default route */}
        <Route path='/' element={<MainLayout />}>
          <Route path="/" element={<Login />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>

        {/* Application Routes */}
        <PrivateRoute path="/" element={<DashboardLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="messages" element={<Messages />} />
          <Route path="applications/completed" element={<CompletedApps />} />
          <Route path="applications/inCompleted" element={<IncompletedApps />} />
          <Route path="applications/missingSnapshots" element={<MissingSnapshotsApps />} />
          <Route path="applications/inProgress" element={<InProgressApps />} />
          <Route path="dlq/message-proxy" element={<DlqMessageProxy />} />
          <Route path="dlq/id-verify-proxy" element={<DlqIdVerifyProxy />} />
          <Route path="dlq/docv-proxy" element={<DlqDocvProxy />} />
          <Route path="dlq/fallback-service" element={<DlqFallbackService />} />
          <Route path="dlq/audit-messages" element={<AuditMessages />} />
        </PrivateRoute>
        <PrivateRoute path="/" element={<MainLayout />}>
          <Route path="unauthorized" element={<Unauthorized />} />
        </PrivateRoute>
    </Routes>
  )
}

export default AppRoutes;