import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import {
    Box,
    Container,
    makeStyles,
} from '@material-ui/core';
import Page from '../../components/Page';
import DLQFallbackTable from './dlqFallbackTable';
import SendIcon from '@material-ui/icons/SendOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Autorenew'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core';
import JsonModal from '../../components/JsonModal/JsonModal';
import ToolTip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const DLQFallbackServicePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
   
    const messages = useSelector(state => state.dlqFallbackProcess?.list?.items);
    const user = useSelector(state => state.session.user);
    const reloadData = () => dispatch(actions.dlqFallbackActions.getDLQFallbackProcessMessagesAction(1));

    const processMessages = (selectedRows, displayData, setSelectedRows) => {
        const messageArray = [];
        const auditMessagesArray = []
        for (const data of selectedRows.data) {
            
            const message = displayData[data.index];
            const request = messages.find(data => data.brokerageAccountId === message.data[0])

            messageArray.push({applicationId: message.data[0], request: request.requestMessage});
            auditMessagesArray.push({
                brokerageAccountId: message.data[0],
                applicationId: message.data[1],
                actionLog: 'processed',
                user: user.account.username,
                dlqName: 'fallback',
                message: request.requestMessage,
            })
           
        }
        dispatch(actions.dlqFallbackActions.processDLQFallbackProcessMessagesAction(messageArray, auditMessagesArray, 1));
        setSelectedRows([]);
    }

    const deleteMessages = (selectedRows, displayData, setSelectedRows) => {
        const messageArray = [];
        const auditMessagesArray = []
        for (const data of selectedRows.data) {
            const message = displayData[data.index];
            const request = messages.find(data => data.brokerageAccountId === message.data[0]);
            messageArray.push(message.data[0]);
            auditMessagesArray.push({
                brokerageAccountId: message.data[0],
                applicationId: message.data[1],
                actionLog: 'deleted',
                user: user.account.username,
                dlqName: 'fallback',
                message: request.requestMessage,
            })
        }
        dispatch(actions.dlqFallbackActions.deleteDLQFallbackProcessMessagesAction(messageArray, auditMessagesArray, 1));
        setSelectedRows([]);
    }

    const handleModalClose = () => dispatch(actions.modalActions.setModalCloseAction());
    const handleModalOpen = meta => {
        if (meta.rowData && meta.rowData[0] && meta.rowData[1]) {
            const applicationId = meta.rowData[1];
            const messageFound = messages.find(message => message.applicationId === applicationId);
            dispatch(actions.modalActions.openModalWithDLQMessage(applicationId, messageFound.requestMessage));
        }
    };

    const openAction = (value, meta, update) => {
        return (
            <IconButton variant="outlined" color="secondary" onClick={() => handleModalOpen(meta)}>
                <OpenInNewIcon />
            </IconButton>
        );
    }

    const columns = [
        {
            name: 'brokerageAccountId',
            label: 'BrokerageAccountId'
        },
        {
            name: 'applicationId',
            label: 'ApplicationId'
        },
        {
            name: 'timeStamp',
            label: 'Sent On',
        },
        {
            name: "Open",
            options: {
                customBodyRender: openAction,
            }
        }
    ]

    const options = {
        filter: true,
        responsive: 'vertical',
        fixedHeader: true,
        pagination: true,
        rowsPerPage: 100,
        customToolbar: () => {
            return (
                <>
                    <ToolTip title={'Refresh'}>
                        <IconButton variant="outlined" onClick={reloadData}>
                            <RefreshIcon />
                        </IconButton>
                    </ToolTip>
                </>
            )
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return (
                <div>
                    <ToolTip title={'Delete Messages'}>
                        <IconButton variant="outlined" onClick={() => deleteMessages(selectedRows, displayData, setSelectedRows)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </ToolTip>
                    <ToolTip title={'Process Messages'}>
                        <IconButton variant="outlined" onClick={() => processMessages(selectedRows, displayData, setSelectedRows)}>
                            <SendIcon />
                        </IconButton>
                    </ToolTip>
                </div>
            )
        }
    };


    useEffect(() => {
        dispatch(actions.dlqFallbackActions.getDLQFallbackProcessMessagesAction(1));
        
    }, [user, dispatch])
    return (
        <Page
            className={classes.root}
            title="Completed"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <DLQFallbackTable
                        title={"Fallback Service Messages"}
                        columns={columns}
                        data={messages}
                        options={options}
                    />
                </Box>
                <JsonModal
                    handleModalClose={handleModalClose}
                />
            </Container>
        </Page>
    )
}

export default DLQFallbackServicePage
