import { getAuditMessagesSucessType } from "../types";
import { getAuditMessagesRequest } from "../../services/auditMessage";
import notifierAction from "./notifierAction";

const getAuditMessaSuccess = (list) => {
  return {
    type: getAuditMessagesSucessType,
    payload: list,
  };
};

const getAuditMessagesAction = () => {
  return (dispatch) => {
    return getAuditMessagesRequest()
      .then(
        (response) => dispatch(getAuditMessaSuccess(response.messages.Items)))
      .catch((error) => dispatch(notifierAction.setErrorMessage(error)));
  };
};

const actions = {
  getAuditMessagesAction,
};

export default actions;
