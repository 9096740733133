import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Typography,
    makeStyles,
    colors
} from '@material-ui/core';
import ActionsIcon from '@material-ui/icons/HelpOutline';
import ReactJson from 'react-json-view';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex'
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    },
    avatar: {
        backgroundColor: colors.green[600],
        height: 56,
        width: 56
    },
}));

const ApplicationBodyCard = ({ className, application, ...rest }) => {
    const classes = useStyles();

    return (
        <Card style={{ maxHeight: 400, overflow: 'auto' }}
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="center"
                    mb={3}
                >
                    <Avatar className={classes.avatar}>
                        <ActionsIcon />
                    </Avatar>
                </Box>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                >
                    Body
                </Typography>
                {application?.applicationText 
                    ? <ReactJson src={JSON.parse(application?.applicationText)} />
                    : <Typography
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                    >
                        No body available
                    </Typography>}

            </CardContent>
        </Card>
    );
};

ApplicationBodyCard.propTypes = {
    className: PropTypes.string
};

export default ApplicationBodyCard;