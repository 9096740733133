import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import actions from '../redux/actions';

let displayed = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications || []);
  const { enqueueSnackbar } = useSnackbar();


  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    const list = notifications.list;
    list.forEach(({ key, message, options = {}, dismissed = false }) => {

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        persist: false,
        ...options,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(actions.notifierActions.removeSnackbar(myKey));
          removeDisplayed(myKey);
        }
      });

      storeDisplayed(key);

    });
  }, [notifications, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
