import {
    getCompletedAppsSuccessType,
    getIncompleteAppsSuccessType,
    getMissingSnapshotsAppsSuccessType,
    getInProgressAppsSuccessType,
    getCompletedAppsPaginationSuccessType,
    getIncompleteAppsPaginationSuccessType,
    getMissingSnapshotsAppsPaginationSuccessType,
    getInProgressAppsPaginationSuccessType,
    getApplicationsCountSuccessType
} from '../types'

const application = (state = {}, action) => {
    switch (action.type) {
        case getCompletedAppsSuccessType:
            return {
                ...state,
                completed: {
                    items: action.payload.list,
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                },
            }
        case getIncompleteAppsSuccessType:
            return {
                ...state,
                incompleted: {
                    items: action.payload.list,
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                },
            }
        case getMissingSnapshotsAppsSuccessType:
            return {
                ...state,
                missingSnapshots: {
                    items: action.payload.list,
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                },
            }
        case getInProgressAppsSuccessType:
            return {
                ...state,
                inProgress: {
                    items: action.payload.list,
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                },
            }
        case getCompletedAppsPaginationSuccessType:
            return {
                ...state,
                completed: {
                    items: [...state.completed.items ,...action.payload.list],
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                }
            }
        case getIncompleteAppsPaginationSuccessType:
            return {
                ...state,
                incompleted: {
                    items: [...state.incompleted.items ,...action.payload.list],
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                }
            }
        case getMissingSnapshotsAppsPaginationSuccessType:
            return {
                ...state,
                missingSnapshots: {
                    items: [...state.missingSnapshots.items ,...action.payload.list],
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                }
            }
        case getInProgressAppsPaginationSuccessType:
            return {
                ...state,
                inProgress: {
                    items: [...state.inProgress.items ,...action.payload.list],
                    lastEvaluatedKey: action.payload.lastEvaluatedKey
                }
            }
        case getApplicationsCountSuccessType:
            return {
                ...state,
                count: action.payload.count
            }
        default:
            return state
    }
}

export default application;