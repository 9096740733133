import React from 'react';
import MUIDataTable from 'mui-datatables';

const messagesTable = ({ data, columns }) => {
  const options = {
    filter: false,
    selectableRows: 'none',
  };

  return (
    <MUIDataTable
      title={"CRM Messages"}
      data={data}
      columns={columns}
      options={options}
    />
  );

}


export default messagesTable;