import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  MessageCircle as MessageIcon,
  AtSign as ApplicationIcon,
  Check as CompleteApplications,
  CheckSquare as IncompleteApplications,
  AlertTriangle as MissingSnapshotsApps,
  Activity as InProgressApps,
  Trash2 as DLQIcon,
  Bookmark as MessageProxyIcon,
  Eye as AuditMessageIcon,
  UserCheck as UserCheckIcon,
  FileText as FileTextIcon,
  Clock as ClockIcon
} from 'react-feather';
import NavItem from './NavItem';
import NavNestedList from './NavNestedList';
import { useSelector } from 'react-redux';


const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/messages',
    icon: MessageIcon,
    title: 'Messages'
  },
  {
    icon: ApplicationIcon,
    title: 'Applications',
    nestedItems: [
      {
        href: '/applications/completed',
        icon: CompleteApplications,
        title: 'Completed'
      },
      {
        href: '/applications/inCompleted',
        icon: IncompleteApplications,
        title: 'Incomplete Processed'
      },
      {
        href: '/applications/missingSnapshots',
        icon: MissingSnapshotsApps,
        title: 'Missing Snapshots'
      },
      {
        href: '/applications/inProgress',
        icon: InProgressApps,
        title: 'In Progress'
      }
    ]
  },
  {
    icon: DLQIcon,
    title: 'DLQ Messages',
    nestedItems: [
      {
        href: '/dlq/message-proxy',
        icon: MessageProxyIcon,
        title: 'Message Proxy'
      },
      {
        href: '/dlq/id-verify-proxy',
        icon: UserCheckIcon,
        title: 'ID Verify Retry'
      },
      {
        href: '/dlq/docv-proxy',
        icon: FileTextIcon,
        title: 'Mitek Dossier'
      },
      {
        href: '/dlq/fallback-service',
        icon: ClockIcon,
        title: 'Fallback Service'
      },
      {
        href: '/dlq/audit-messages',
        icon: AuditMessageIcon,
        title: 'Audit Messages'
      }
    ]
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: 10
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const userEmail = useSelector(state => state.session.user.email);

  const [user] = useState({
    avatar: '.',
    jobTitle: 'Maintainer',
    name: userEmail
  })

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            item.nestedItems ?
              <NavNestedList
                key={item.title}
                title={item.title}
                icon={item.icon} 
                nestedItems= {item.nestedItems}/>
              :
              < NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
