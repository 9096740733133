import {
    getDLQFallbackProcessSuccessType
} from '../types'
import {
    getDLQFallbackProcessMessageByTypeRequest,
    deleteDLQFallbackProcessMessagesByIdRequest,
    processDLQFallbackProcessMessagesByTypeRequest
} from '../../services/dlqFallbackProcessService';

import { addAuditMessagesRequest } from '../../services/auditMessage';
import notifierAction from './notifierAction';


const getDLQFallbackProcessSuccess = (response) => {
    return {
        type: getDLQFallbackProcessSuccessType,
        payload: response
    }
}


const getDLQFallbackProcessMessagesAction = (type) => {
    return (dispatch) => {
        return getDLQFallbackProcessMessageByTypeRequest(type)
            .then(response => dispatch(getDLQFallbackProcessSuccess(response)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const deleteDLQFallbackProcessMessagesAction = (messages, auditMessagesArray, type) => {
    return (dispatch) => {
        return deleteDLQFallbackProcessMessagesByIdRequest(messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest(auditMessagesArray))
            .then(() => dispatch(getDLQFallbackProcessMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const processDLQFallbackProcessMessagesAction = (messages, auditMessagesArray, type) => {
    return (dispatch) => {
        return processDLQFallbackProcessMessagesByTypeRequest(type, messages)
            .then(response => dispatch(notifierAction.setSuccessMessage(response)))
            .then(()=> addAuditMessagesRequest(auditMessagesArray))
            .then(() => dispatch(getDLQFallbackProcessMessagesAction(type)))
            .catch(error => dispatch(notifierAction.setErrorMessage(error)));
    }
}

const actions = {
    getDLQFallbackProcessMessagesAction,
    deleteDLQFallbackProcessMessagesAction,
    processDLQFallbackProcessMessagesAction
}

export default actions;