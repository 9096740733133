import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import {
    Box,
    Container,
    makeStyles,
} from '@material-ui/core';
import Page from '../../components/Page';
import ApplicationsTable from './applicationsTable';
import SendIcon from '@material-ui/icons/SendOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Autorenew'
import AddIcon from '@material-ui/icons/Add'
import { IconButton } from '@material-ui/core';
import JsonModal from '../../components/JsonModal/JsonModal';
import ToolTip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const IncompletedApps = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const applications = useSelector(state => state.application?.incompleted?.items);
    const lastEvaluatedKey = useSelector(state => state.application?.incompleted?.lastEvaluatedKey);
    const user = useSelector(state => state.session.user);

    const handlePublishClick = (meta) => {
        const rowData = meta.rowData;
        const type = rowData[1];
        const applicationId = rowData[0]

        dispatch(actions.applicationActions.publishApplicationAction(type, applicationId));
    }

    const reloadData = () => dispatch(actions.applicationActions.getApplicationsAction(2));

    const publishAction = (value, meta, update) => {
        return (
            <IconButton variant="outlined" color="secondary" onClick={() => handlePublishClick(meta)}>
                <SendIcon />
            </IconButton>
        );
    }

    const handleModalClose = () => dispatch(actions.modalActions.setModalCloseAction());
    const handleModalOpen = meta => {
        if (meta.rowData && meta.rowData[0]) {
            const applicationId = meta.rowData[0];
            dispatch(actions.modalActions.openModalWithApplication(applicationId));
        }
    };

    const openAction = (value, meta, update) => {
        return (
            <IconButton variant="outlined" color="secondary" onClick={() => handleModalOpen(meta)}>
                <OpenInNewIcon />
            </IconButton>
        );
    }

    const getApplicationChunk = () => {
        if(lastEvaluatedKey){
            dispatch(actions.applicationActions.getApplicationsPaginationAction(2, lastEvaluatedKey));
        }
    }

    const columns = [
        {
            name: 'applicationId',
            label: 'ApplicationId'
        },
        {
            name: 'applicationStatus',
            label: 'Application Status',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'createdOn',
            label: 'Created On',
        },
        {
            name: "Send",
            options: {
                customBodyRender: publishAction,
            }
        },
        {
            name: "Open",
            options: {
                customBodyRender: openAction,
            }
        }
    ]

    const options = {
        filter: false,
        selectableRows: 'none',
        responsive: 'vertical',
        fixedHeader: true,
        pagination: false,
        customToolbar: () => {
            return (
                <>
                    <ToolTip title={'Load More Applications'}>
                        <IconButton variant="outlined" onClick={getApplicationChunk}>
                            <AddIcon />
                        </IconButton>
                    </ToolTip>
                    <ToolTip title={'Refresh'}>
                        <IconButton variant="outlined" onClick={reloadData}>
                            <RefreshIcon />
                        </IconButton>
                    </ToolTip>
                </>
            )
        }
    };

    useEffect(() => {
        dispatch(actions.applicationActions.getApplicationsAction(2));
    }, [user, dispatch])


    return (
        <Page
            className={classes.root}
            title="Incompleted"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <ApplicationsTable
                        title={"Incompleted Applications"}
                        columns={columns}
                        data={applications}
                        options={options}
                    />
                </Box>
                <JsonModal
                    handleModalClose={handleModalClose}
                />
            </Container>
        </Page>
    )
}

export default IncompletedApps
